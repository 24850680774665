/* NotificationMessages Component Styles */
.notification-messages-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

/* Filter Styles */
.filter-container {
  background-color: #f8f9fa;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
}

.filter-header h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.filter-badge {
  display: inline-block;
  background-color: #28a745;
  color: white;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
}

.filter-toggle-button {
  background: none;
  border: none;
  color: #6c757d;
  font-size: 13px;
  cursor: pointer;
}

.applied-filters-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  background-color: #f0f0f0;
  font-size: 13px;
  color: #495057;
}

.reset-filters-button {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  font-size: 13px;
  text-decoration: underline;
}

.filter-section {
  padding: 15px;
  border-top: 1px solid #eee;
}

.filter-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.filter-item {
  flex: 1;
}

.filter-item label {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.btn-secondary {
  background-color: #6c757d;
  color: white;
  border-color: #6c757d;
}

/* Table Styles */
.table-container {
  flex: 1;
  overflow: auto;
  padding: 0 10px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background-color: #2a2a2a; /* Dark background for the table */
  color: #e0e0e0; /* Light text color for better contrast */
}

.data-table th {
  position: sticky;
  top: 0;
  background-color: #333333; /* Darker header background */
  padding: 10px;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #444444;
  white-space: nowrap;
  z-index: 1;
}

.data-table td {
  padding: 12px 10px;
  border-bottom: 1px solid #444444;
  vertical-align: top;
}

.data-table tr {
  background-color: #2a2a2a; /* Base dark color for odd rows */
}

.data-table tr:nth-child(even) {
  background-color: #323232; /* Slightly lighter for even rows */
}

.data-table tr:hover {
  background-color: #3a3a3a; /* Highlight color on hover */
}

.timestamp-cell {
  white-space: nowrap;
}

.channel-cell {
  text-align: center;
}

.channel-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  min-width: 60px;
}

/* Update badge colors for better contrast with dark theme */
.email-badge {
  background-color: #a0a0a0; /* Light grey */
  color: #1a1a1a; /* Dark text for contrast */
}

.sms-badge {
  background-color: #ff9940; /* Brighter orange */
  color: white;
}

.recipient-cell {
  min-width: 180px;
}

.recipient-info {
  display: flex;
  flex-direction: column;
}

.recipient-name {
  font-weight: 500;
  margin-bottom: 3px;
}

.recipient-contact {
  font-size: 13px;
  color: #a0a0a0; /* Lighter gray for secondary text */
}

.subject-cell {
  min-width: 200px;
  color: #e0e0e0;
  font-weight: 500;
}

.message-cell {
  width: 100%;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.no-data {
  text-align: center;
  padding: 30px;
  color: #6c757d;
  font-style: italic;
}

/* Pagination Styles */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 15px 0;
  padding: 10px 0;
  border-top: 1px solid #e9ecef;
}

.pagination-button {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background-color: #e9ecef;
}

.page-info {
  margin: 0 10px;
  color: #495057;
  font-size: 14px;
}

/* Messages Summary */
.messages-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  color: #6c757d;
  border-top: 1px solid #e9ecef;
}

.refresh-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 13px;
}

/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: #6c757d;
}

/* Error Message */
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* Message Detail Modal Styles */
.message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.message-modal {
  background-color: #2a2a2a;
  border: 1px solid #444;
  border-radius: 6px;
  width: 800px;
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.message-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #444;
}

.message-modal-header h3 {
  margin: 0;
  color: #e0e0e0;
  font-size: 1.2rem;
}

.modal-close-btn {
  background: transparent;
  border: none;
  color: #a0a0a0;
  font-size: 1.5rem;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  line-height: 1;
}

.modal-close-btn:hover {
  background-color: rgba(160, 160, 160, 0.2);
  color: #e0e0e0;
}

.message-modal-content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  max-height: 70vh;
}

/* Consolidated metadata styles */
.message-metadata {
  background-color: #333;
  border-radius: 6px;
  padding: 12px 15px;
  margin-bottom: 16px;
  border-left: 3px solid #444;
}

.metadata-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.metadata-row:last-child {
  margin-bottom: 0;
}

.metadata-group {
  display: flex;
  align-items: baseline;
  margin-right: 12px;
}

.metadata-label {
  font-weight: 600;
  color: #a0a0a0;
  margin-right: 6px;
  font-size: 0.85rem;
}

.metadata-value {
  color: #e0e0e0;
  font-size: 0.95rem;
}

.metadata-secondary {
  color: #a0a0a0;
  font-size: 0.85rem;
  margin-left: 6px;
}

/* Message content styles */
.message-main-content {
  margin-bottom: 16px;
}

.message-detail-item {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.message-detail-label {
  font-weight: 600;
  color: #a0a0a0;
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.message-detail-value {
  color: #e0e0e0;
  line-height: 1.4;
}

.message-content {
  background-color: #333;
  padding: 12px;
  border-radius: 4px;
  border-left: 3px solid #444;
  white-space: pre-wrap;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

.message-content img {
  max-width: 100%;
  height: auto;
}

.message-content iframe {
  max-width: 100%;
}

.message-content table {
  max-width: 100%;
  width: auto;
}

.message-content * {
  max-width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
}

.html-content-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.message-id {
  font-family: monospace;
  font-size: 0.85rem;
  color: #a0a0a0;
  word-break: break-all;
}

.message-modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #444;
  display: flex;
  justify-content: flex-end;
}

.close-button {
  padding: 8px 16px;
  background-color: #444;
  color: #e0e0e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: #555;
}

/* Footer info styles */
.message-footer-info {
  display: flex;
  justify-content: space-between;
  background-color: #333;
  border-radius: 6px;
  padding: 10px 15px;
  margin-top: 10px;
  border-left: 3px solid #444;
  font-size: 0.85rem;
}

.message-id-small {
  font-family: monospace;
  font-size: 0.8rem;
  color: #a0a0a0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

/* Make table rows clickable */
.data-table tr {
  cursor: pointer;
} 