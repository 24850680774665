/* Debug styles for drag and drop */
.component-wrapper.dragging {
  opacity: 0.8;
  transition: none !important;
  box-shadow: 0 0 15px rgba(0, 100, 255, 0.5);
  will-change: transform;
}

.component-wrapper.resizing {
  opacity: 0.9;
  transition: none !important;
  box-shadow: 0 0 15px rgba(255, 100, 0, 0.5);
  will-change: width, height;
}

.component-state-indicator {
  font-size: 10px;
  color: #ff5722;
  margin-left: 5px;
}

/* Add a debug button to the component header */
.debug-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 10px;
  cursor: pointer;
}

.debug-button:hover {
  background-color: #555;
}

/* Debug panel */
.debug-panel {
  position: absolute;
  top: 100%;
  left: 0;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  z-index: 9999;
  max-height: 300px;
  overflow-y: auto;
}

.debug-panel h4 {
  margin: 0 0 8px 0;
  font-size: 14px;
  color: #4fc3f7;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
}

.debug-panel-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.debug-panel-label {
  color: #aaa;
}

.debug-panel-value {
  color: #4fc3f7;
  font-family: monospace;
}

/* Debug overlay during drag/resize */
.debug-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 10px;
  padding: 2px 4px;
  z-index: 9999;
  pointer-events: none;
  font-family: monospace;
}

/* System-wide debug panel */
.system-debug-panel {
  position: fixed;
  bottom: 50px;
  right: 10px;
  width: 300px;
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  z-index: 10000;
  max-height: 500px;
  overflow-y: auto;
}

.system-debug-panel h3 {
  margin: 0 0 10px 0;
  font-size: 16px;
  color: #4fc3f7;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
}

.system-debug-panel h4 {
  margin: 15px 0 8px 0;
  font-size: 14px;
  color: #4fc3f7;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
}

/* Component list in system debug panel */
.component-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #444;
  border-radius: 3px;
  margin-top: 5px;
}

.component-list-item {
  display: flex;
  padding: 4px 6px;
  border-bottom: 1px solid #333;
  font-family: monospace;
  font-size: 11px;
}

.component-list-item:last-child {
  border-bottom: none;
}

.component-list-id {
  color: #ff9800;
  width: 70px;
}

.component-list-type {
  color: #4caf50;
  width: 100px;
}

.component-list-pos {
  color: #e91e63;
}

/* System debug button */
.system-debug-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10000;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
}

.system-debug-button:hover {
  background: #555;
} 