/* Time Chart Styles */
.time-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.time-chart-content {
  flex: 1 1 auto;
  min-height: 200px;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box;
}

.component-content {
  height: calc(100% - 30px); /* Adjust based on your header height */
  overflow: auto;
  padding: 0 !important; /* Override any padding to maximize space */
}

/* Chart loading and error states */
.chart-loading,
.chart-error,
.chart-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 150px;
  color: #e4c87e;
  font-size: 12px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(228, 200, 126, 0.3);
  border-radius: 50%;
  border-top: 4px solid #e4c87e;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Time chart footer */
.time-chart-footer {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}

/* Debug toggle button */
.debug-toggle {
  background-color: #2a2a2a;
  color: #e4c87e;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
}

.debug-toggle:hover {
  background-color: #3a3a3a;
}

/* Chart tooltip */
.recharts-tooltip-wrapper {
  z-index: 1000;
}

/* Override Recharts padding for tighter layout */
.recharts-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}

.recharts-cartesian-grid {
  width: 100% !important;
}

/* Ensure charts scale properly on smaller screens */
@media (max-width: 768px) {
  .time-chart-content {
    min-height: 200px;
  }
}

@media (max-height: 600px) {
  .time-chart-content {
    min-height: 150px;
  }
}

@media (max-height: 500px) {
  .time-chart-content {
    min-height: 120px;
  }
  
  .recharts-legend-wrapper {
    font-size: 10px;
  }
}

/* Override Recharts default styles for compact charts */
.recharts-legend-item {
  margin-right: 10px !important;
}

.recharts-legend-item-text {
  font-size: 10px !important;
}

.recharts-cartesian-axis-tick-value {
  font-size: 10px !important;
}

.recharts-tooltip-wrapper {
  font-size: 11px !important;
}

/* Ensure dropdowns appear above the modal */
.ant-select-dropdown {
  z-index: 9999999 !important;
}

/* Modal styling */
.data-source-config-modal .ant-form-item {
  position: relative;
} 