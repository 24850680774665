/* Component System Styles */

/* Component Wrapper */
.component-wrapper {
  position: absolute;
  background-color: #2a2a2a;
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #444;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  min-width: 200px;
  min-height: 150px;
  max-width: 100%;
  max-height: 100%;
  /* Base z-index for components - individual components will still stack based on their z-index property */
  z-index: 1000;
}

/* Maximized component styles */
.component-wrapper.maximized {
  border-radius: 0;
  box-shadow: none;
  border: none;
  /* Ensure maximized windows are always above other windows but below side menu */
  z-index: 9000000 !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

/* Standard modal z-index - all modals should use this or higher to ensure they are above all windows */
.modal-overlay {
  z-index: 9500000 !important;
}

.modal-content {
  z-index: 9500001 !important;
}

.component-wrapper.maximized .component-resize-handle {
  display: none;
}

/* Locked component styles */
.component-wrapper.locked {
  border: 1px solid #777;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.component-wrapper.locked .component-header {
  background-color: #444;
  cursor: default;
}

.component-wrapper.locked .component-resize-handle {
  display: none;
}

/* Lock icon button styles */
.lock-icon-button {
  opacity: 0.8;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.lock-icon-button:hover {
  opacity: 1;
  transform: scale(1.1);
}

/* Activity log specific styles */
.activity-log-component {
  /* Allow component to follow normal z-index stacking */
}

/* Dragging state - explicitly disable transitions */
.component-wrapper.dragging,
.component-wrapper.resizing,
.component-wrapper.post-drag-lock {
  transition: none !important;
  left: var(--drag-x) !important;
  top: var(--drag-y) !important;
}

/* Disable all transitions after drag to prevent unwanted animations */
.component-wrapper.post-drag-lock * {
  transition: none !important;
}

/* Re-enable transitions 1 second after drag ends to allow position to stabilize */
@keyframes enable-transitions {
  to {
    transition: box-shadow 0.2s ease, transform 0.2s ease;
  }
}

.component-wrapper:not(.dragging):not(.resizing):not(.post-drag-lock) {
  animation: enable-transitions 0s 1s forwards;
}

.component-wrapper:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
}

/* Component Header */
.component-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #333;
  cursor: move;
  user-select: none;
  border-bottom: 1px solid #444;
  min-height: 40px;
}

.component-title {
  font-weight: 500;
  color: #e4c87e;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  margin-right: 8px;
}

.component-controls {
  display: flex;
  gap: 6px;
  flex-shrink: 0;
}

.component-control {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  font-size: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  width: 24px;
  height: 24px;
}

.component-control:hover {
  background-color: #444;
  color: #fff;
}

/* Component Content */
.component-content {
  overflow: auto;
  height: calc(100% - 30px); /* Header height is typically 30px */
  position: relative;
}

.component-content.fullheight {
  height: 100%;
}

/* Chart-specific component styling */
.component-wrapper[data-component-type="chart"] .component-content,
.component-wrapper[data-component-type="timeChart"] .component-content {
  padding: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px); /* Account for header */
  margin: 0;
}

.component-wrapper[data-component-type="chart"] .component-content > div,
.component-wrapper[data-component-type="timeChart"] .component-content > div {
  height: 100%;
  padding: 0;
  margin: 0;
}

/* Make charts take full width */
.component-wrapper[data-component-type="chart"] .recharts-responsive-container,
.component-wrapper[data-component-type="timeChart"] .recharts-responsive-container {
  width: 100% !important;
}

/* Smaller charts on compact layouts */
@media (max-height: 800px) {
  .component-wrapper[data-component-type="chart"] .component-content,
  .component-wrapper[data-component-type="timeChart"] .component-content {
    min-height: 150px;
  }
}

@media (max-height: 600px) {
  .component-wrapper[data-component-type="chart"] .component-content,
  .component-wrapper[data-component-type="timeChart"] .component-content {
    min-height: 120px;
  }
}

/* Resize Handle */
.component-resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  cursor: nwse-resize;
  background: linear-gradient(135deg, transparent 50%, #e4c87e 50%);
  border-radius: 0 0 4px 0;
  z-index: 10;
}

/* Page Container */
.page-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 60px);
  background-color: #1e1e1e;
  overflow: hidden;
}

/* Medium screens - windows shrink proportionally */
@media (min-width: 769px) and (max-width: 1200px) {
  .component-wrapper {
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }
}

/* Small screens - windows stack vertically */
@media (max-width: 768px) {
  .component-wrapper {
    position: relative !important;
    margin-bottom: 16px;
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    height: auto !important;
    min-height: 200px;
    max-height: 80vh;
  }
  
  .page-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    overflow-y: auto;
    height: auto;
  }
  
  .component-content {
    max-height: 60vh;
  }
  
  .component-resize-handle {
    display: none;
  }
}

/* Reorganize Button */
.reorganize-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #e4c87e;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 5000;
  transition: all 0.2s ease;
  animation: fadeIn 0.3s ease;
}

.reorganize-button:hover {
  background-color: #d4b86e;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Component Library Modal */
.component-library-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 95000;
  animation: fadeIn 0.2s ease;
}

.component-library-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  width: 80%;
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 96000;
  animation: fadeIn 0.3s ease;
  display: flex;
  flex-direction: column;
}

.component-library-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #444;
  background-color: #333;
}

.component-library-modal-header h3 {
  margin: 0;
  color: #e4c87e;
  font-size: 1.2rem;
}

.component-library-close-btn {
  background: transparent;
  border: none;
  color: #e4c87e;
  font-size: 1.5rem;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  line-height: 1;
}

.component-library-close-btn:hover {
  background-color: rgba(228, 200, 126, 0.2);
}

.component-library-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
}

.component-library-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #444;
  padding: 1.5rem 1rem;
  height: 140px;
}

.component-library-item:hover {
  background-color: #3a3a3a;
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  border-color: #e4c87e;
}

.component-library-item-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #e4c87e;
}

.component-library-item-name {
  font-size: 0.9rem;
  text-align: center;
  color: #ddd;
  font-weight: 500;
}

@media (max-width: 768px) {
  .component-library-modal {
    width: 95%;
    max-height: 90vh;
  }
  
  .component-library-items {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .component-library-item {
    height: auto;
    padding: 1rem;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
  }
  
  .component-library-item-icon {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

/* Page Selector */
.page-selector {
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #2a2a2a;
  border-radius: 6px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.page-selector-item {
  padding: 8px 16px;
  background-color: #333;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  color: #ddd;
  border: 1px solid #444;
}

.page-selector-item.active {
  background-color: #444;
  color: #e4c87e;
  border-color: #e4c87e;
}

/* Config Panel */
.config-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #2a2a2a;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.config-panel.open {
  transform: translateX(0);
}

.config-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.config-panel-title {
  font-size: 18px;
  font-weight: 500;
  color: #e4c87e;
}

.config-panel-close {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  font-size: 16px;
}

.config-panel-section {
  margin-bottom: 20px;
}

.config-panel-section-title {
  font-size: 14px;
  font-weight: 500;
  color: #ddd;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #444;
}

/* Form Controls */
.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  color: #bbb;
  font-size: 12px;
}

.form-control {
  width: 100%;
  padding: 8px 10px;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: #ddd;
  font-size: 14px;
}

.form-control:focus {
  outline: none;
  border-color: #e4c87e;
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23aaa' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 30px;
}

/* Buttons */
.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.btn-primary {
  background-color: #e4c87e;
  color: #333;
}

.btn-primary:hover {
  background-color: #d4b86e;
}

.btn-secondary {
  background-color: #444;
  color: #ddd;
}

.btn-secondary:hover {
  background-color: #555;
}

.btn-danger {
  background-color: #e45c5c;
  color: #fff;
}

.btn-danger:hover {
  background-color: #d44c4c;
}

/* Shared Pages Library */
.shared-pages-library {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.shared-pages-search {
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.shared-pages-search-input {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #555;
  border-radius: 6px;
  background-color: #2a2a2a;
  color: #ffffff;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}

.shared-pages-search-input:focus {
  border-color: #e4c87e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(228, 200, 126, 0.3);
}

.shared-pages-loading,
.shared-pages-error,
.shared-pages-empty {
  padding: 1rem;
  text-align: center;
  color: #999;
  font-style: italic;
}

.shared-pages-error {
  color: #ff6b6b;
}

.shared-pages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 1rem;
  overflow-y: auto;
  padding: 0.5rem;
  flex: 1;
}

.shared-page-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #333;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #444;
  padding: 1rem;
  height: 140px;
  position: relative;
  overflow: hidden;
}

.shared-page-item:hover {
  background-color: #3a3a3a;
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  border-color: #e4c87e;
}

.shared-page-item-icon {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #e4c87e;
  z-index: 1;
}

.shared-page-item-label {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  text-align: center;
}

.shared-page-item-name {
  font-size: 1rem;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  text-shadow: 0px 1px 2px rgba(0,0,0,0.8);
}

.shared-page-item-creator {
  font-size: 0.8rem;
  color: #cccccc;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shared-page-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
  z-index: 99999;
  animation: fadeIn 0.3s ease;
}

.shared-page-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #444;
  background-color: #333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.shared-page-preview-header h4 {
  margin: 0;
  color: #e4c87e;
}

.shared-page-preview-close {
  background: transparent;
  border: none;
  color: #ddd;
  font-size: 1.5rem;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
  line-height: 1;
}

.shared-page-preview-close:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.shared-page-preview-content {
  padding: 1rem;
}

.shared-page-description {
  margin-bottom: 1rem;
  color: #ccc;
  font-size: 0.9rem;
  line-height: 1.4;
}

.shared-page-metadata {
  background-color: #333;
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: #bbb;
}

.shared-page-metadata > div {
  margin-bottom: 0.5rem;
}

.shared-page-metadata > div:last-child {
  margin-bottom: 0;
}

.shared-page-metadata strong {
  color: #ddd;
  margin-right: 0.5rem;
}

.shared-page-components {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #bbb;
}

.shared-page-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}

.shared-page-copy-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #e4c87e;
  color: #333;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.shared-page-copy-btn:hover {
  background-color: #d4b86e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.shared-page-copy-btn:disabled {
  opacity: 0.7;
  cursor: default;
  box-shadow: none;
}

.shared-page-delete-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 107, 107, 0.2);
  color: #ff6b6b;
  border: 1px solid rgba(255, 107, 107, 0.4);
  border-radius: 4px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.shared-page-delete-btn:hover {
  background-color: rgba(255, 107, 107, 0.3);
  border-color: rgba(255, 107, 107, 0.6);
  color: #ff8080;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.shared-page-delete-btn:disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.component-library-tabs {
  display: flex;
  border-bottom: 1px solid #444;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  background-color: #2a2a2a;
  z-index: 1;
}

.component-library-tab {
  padding: 0.75rem 1.5rem;
  background: none;
  border: none;
  color: #999;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 2px solid transparent;
}

.component-library-tab.active {
  color: #e4c87e;
  border-bottom-color: #e4c87e;
  font-weight: 500;
}

.component-library-tab:hover:not(.active) {
  color: #ddd;
  background-color: rgba(228, 200, 126, 0.05);
} 