.water-flow-stats-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  background-color: #1a1a1a;
  color: #e4c87e;
}

/* Hide the gear icon for this component */
div[data-component-type="water-flow-stats"] .component-controls button[title="Settings"] {
  display: none !important;
}

.error-message {
  color: #ff6b6b;
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: rgba(255, 107, 107, 0.1);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #e4c87e;
}

.table-container {
  flex: 1;
  overflow: auto;
  margin-top: 16px;
}

.water-flow-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2a2a2a;
  border-radius: 4px;
  overflow: hidden;
}

.water-flow-table th,
.water-flow-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #3a3a3a;
}

.water-flow-table th {
  background-color: #333;
  font-weight: 600;
  color: #e4c87e;
}

.water-flow-table tr:last-child td {
  border-bottom: none;
}

.water-flow-table tr:hover {
  background-color: #333;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .water-flow-table th,
  .water-flow-table td {
    padding: 8px;
    font-size: 0.9rem;
  }
}

/* Style for the refresh icon to properly align with other controls */
.refresh-icon.rotating {
  animation: rotate 1s linear infinite;
  display: inline-block;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 