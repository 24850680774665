/* Base styles */
.App {
  text-align: center;
  height: 100vh;
  width: 100%;
  background: radial-gradient(ellipse at center, #5a4d36 0%, #332b1e 70%, #211c13 100%);
  font-family: 'Times New Roman', serif;
  color: #e4c87e;
  overflow: hidden;
}

/* Updated Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1a1a1a;
  border-bottom: 1px solid #333;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

/* Hamburger button */
.hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.hamburger-line {
  width: 100%;
  height: 2px;
  background-color: #e4c87e;
  transition: all 0.3s ease;
}

/* Side Menu */
.side-menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100vh;
  background-color: #1a1a1a;
  border-right: 1px solid #333;
  z-index: 999999 !important;
  transition: left 0.3s ease;
  overflow-y: auto;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.3);
}

.side-menu.open {
  left: 0;
}

.side-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999998 !important;
}

.side-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #333;
}

.side-menu-header h3 {
  margin: 0;
  color: #e4c87e;
}

.close-menu-btn {
  background: transparent;
  border: none;
  color: #e4c87e;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Side Menu Content */
.side-menu-content {
  padding: 1rem;
  position: relative;
  z-index: 999999 !important; /* Match the z-index of side-menu */
}

.side-menu-section {
  margin-bottom: 2rem;
  position: relative;
  z-index: 999999 !important; /* Match the z-index of side-menu */
}

/* Other components that might appear on top of side menu */
.modal-window,
.dialog-window {
  z-index: 90000 !important; /* High, but lower than side menu */
}

/* Section header with collapse functionality */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.collapse-icon {
  color: #e4c87e;
  font-size: 0.75rem;
  transition: transform 0.2s ease;
}

.collapse-icon.collapsed {
  transform: rotate(0deg);
}

.section-content {
  margin-top: 0.5rem;
  transition: max-height 0.3s ease;
}

.side-menu-section h4 {
  color: #e4c87e;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #333;
}

.side-menu-link {
  display: block;
  color: #e4c87e;
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 0.9rem;
}

.side-menu-link:hover {
  text-decoration: underline;
}

/* Page icons */
.page-icons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.page-icon {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
}

.page-icon:hover {
  background-color: rgba(228, 200, 126, 0.1);
}

.page-icon.active {
  background-color: rgba(228, 200, 126, 0.2);
}

.page-icon-symbol {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.page-icon-name {
  color: #e4c87e;
  font-size: 0.9rem;
  flex-grow: 1;
}

.delete-page-btn {
  color: #e4c87e;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  font-size: 0.9rem;
  padding: 0 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-icon:hover .delete-page-btn {
  opacity: 0.7;
}

.delete-page-btn:hover {
  opacity: 1 !important;
  color: #ff6b6b;
}

/* Modal styles for delete confirmation */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000000;
}

.delete-page-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 6px;
  padding: 0;
  width: 90%;
  max-width: 450px;
  z-index: 1000001;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #333;
}

.modal-header h3 {
  margin: 0;
  color: #e4c87e;
}

.modal-close-btn {
  background: transparent;
  border: none;
  color: #e4c87e;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.modal-content {
  padding: 1.5rem;
}

.delete-warning {
  display: flex;
  align-items: center;
  background-color: rgba(255, 107, 107, 0.1);
  padding: 0.8rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  color: #ff6b6b;
}

.warning-icon {
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.confirmation-input {
  margin-top: 1.5rem;
}

.confirmation-input label {
  display: block;
  margin-bottom: 0.5rem;
  color: #e4c87e;
}

.confirmation-input input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #2b2b2b;
  color: #e4c87e;
  font-size: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #333;
}

.cancel-button {
  padding: 0.6rem 1.2rem;
  background-color: transparent;
  border: 1px solid #555;
  border-radius: 4px;
  color: #e4c87e;
  cursor: pointer;
  font-size: 0.9rem;
}

.delete-button {
  padding: 0.6rem 1.2rem;
  background-color: rgba(255, 107, 107, 0.7);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.delete-button:hover:not(:disabled) {
  background-color: rgba(255, 107, 107, 1);
}

.delete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading indicator */
.loading-indicator {
  color: #e4c87e;
  text-align: center;
  padding: 10px;
  font-style: italic;
}

/* Message for no pages */
.no-pages-message {
  color: #e4c87e;
  text-align: center;
  padding: 10px;
  font-style: italic;
  opacity: 0.8;
}

/* Debug section */
.debug-section {
  margin-top: 2rem;
  border-top: 1px dashed #555;
  padding-top: 1rem;
}

.debug-section .section-content p {
  margin: 0.25rem 0;
  color: #aaa;
  font-size: 0.8rem;
  font-family: monospace;
}

/* Add page form */
.add-page-form {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.add-page-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #2b2b2b;
  color: #e4c87e;
}

.add-page-button {
  padding: 0.5rem 1rem;
  background-color: rgba(228, 200, 126, 0.2);
  border: 1px solid rgba(228, 200, 126, 0.3);
  border-radius: 4px;
  color: #e4c87e;
  cursor: pointer;
}

.add-page-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Component library button */
.component-library-button {
  width: 100%;
  padding: 0.5rem;
  background-color: rgba(228, 200, 126, 0.2);
  border: 1px solid rgba(228, 200, 126, 0.3);
  border-radius: 4px;
  color: #e4c87e;
  cursor: pointer;
  font-size: 0.9rem;
}

/* Updated Component Library */
.component-library {
  background-color: #1a1a1a;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.component-library h3 {
  color: #e4c87e;
  margin-top: 0;
  margin-bottom: 1rem;
}

.component-library-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.component-library-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.component-library-item:hover {
  background-color: rgba(228, 200, 126, 0.1);
}

.component-library-item-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #e4c87e;
}

.component-library-item-name {
  font-size: 0.8rem;
  text-align: center;
  color: #e4c87e;
}

/* Updated Page Container */
.page-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }
  
  .side-menu {
    width: 250px;
  }
  
  .page-container {
    grid-template-columns: 1fr;
  }
}

/* Container for the splash screen */
.splash-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3% 5%;
  box-sizing: border-box;
  position: relative;
}

/* Button row styles */
.button-row {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  z-index: 2;
  margin: 0 auto;
}

/* Circular button styles */
.circular-button {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(ellipse at center, #4d4126 0%, #3a3019 100%);
  border: 1px solid #e4c87e65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 15px 5px rgba(228, 200, 126, 0.3);
  margin: 0 30px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.circular-button:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px 10px rgba(228, 200, 126, 0.4);
}

/* Building icon placeholders */
.building-icon {
  width: 60px;
  height: 40px;
  margin-bottom: 8px;
  border: 1px solid rgba(228, 200, 126, 0.6);
  opacity: 0.8;
}

/* Specific building styles */
.water {
  background: linear-gradient(135deg, 
    transparent 0%, transparent 40%, 
    #6badd6 40%, #6badd6 60%, 
    transparent 60%, transparent 100%);
  border-radius: 5px;
}

.greenhouse {
  border-radius: 50% 50% 0 0;
  height: 30px;
  border-bottom: none;
  position: relative;
}

.greenhouse:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 10px;
  border: 1px solid rgba(228, 200, 126, 0.6);
  border-top: none;
}

.documentation {
  background: linear-gradient(to bottom, 
    transparent 0%, transparent 20%, 
    #e4c87e50 20%, #e4c87e50 25%, 
    transparent 25%, transparent 45%,
    #e4c87e50 45%, #e4c87e50 50%, 
    transparent 50%, transparent 70%,
    #e4c87e50 70%, #e4c87e50 75%, 
    transparent 75%, transparent 100%);
}

.power {
  position: relative;
}

.power:before {
  content: '';
  position: absolute;
  top: 10%;
  left: 50%;
  height: 60%;
  width: 5px;
  background: #e4c87e90;
  transform: translateX(-50%);
}

.power:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 25%;
  width: 50%;
  height: 15px;
  background: #e4c87e50;
  border-radius: 5px;
}

/* Button text */
.button-text {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #e4c87e;
  text-align: center;
  line-height: 1.2;
}

/* Center logo styles */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(ellipse at center, rgba(90, 77, 54, 0.3) 0%, rgba(51, 43, 30, 0) 70%);
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease;
}

/* When user is not logged in, make the logo larger */
.unauthenticated .center-logo {
  width: 300px;
  height: 300px;
}

.unauthenticated .gr-logo-image {
  width: 250px;
}

.gr-logo-image {
  width: 200px;
  height: auto;
  display: block;
  filter: drop-shadow(0 0 15px rgba(228, 200, 126, 0.7));
  object-fit: contain;
  transition: transform 0.3s ease-in-out, width 0.3s ease;
}

.gr-logo-image:hover {
  transform: scale(1.05);
}

/* Splash page actions */
.splash-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 300px;
}

.splash-button {
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  width: 100%;
}

.login-button {
  background-color: #e4c87e;
  color: #333;
}

.login-button:hover {
  background-color: #d4b86e;
}

.register-button {
  background-color: transparent;
  color: #e4c87e;
  border: 1px solid #e4c87e;
}

.register-button:hover {
  background-color: rgba(228, 200, 126, 0.1);
}

/* User profile link in navbar */
.user-profile-link {
  position: relative;
  transition: color 0.2s ease;
}

.user-profile-link:hover {
  color: #f5dfa0 !important;
}

.user-profile-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #e4c87e;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.user-profile-link:hover:after {
  transform: scaleX(1);
}

/* Page icon controls */
.page-icon-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.page-lock-icon {
  margin-right: 5px;
  opacity: 0.7;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.page-lock-icon:hover {
  opacity: 1;
  transform: scale(1.1);
}

.page-icon.locked {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Dashboard notifications */
.dashboard-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 20px;
  border-radius: 6px;
  color: #fff;
  font-weight: 500;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999999;
  animation: notification-fade-in 0.3s ease forwards;
}

@keyframes notification-fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dashboard-notification.info {
  background-color: #2196f3;
}

.dashboard-notification.warning {
  background-color: #ff9800;
}

.dashboard-notification.error {
  background-color: #f44336;
}

/* Add nav-links styles */
.nav-links {
  list-style: none;
  padding: 0;
  margin: 0 0 10px 0;
}

.nav-links li {
  margin-bottom: 10px;
}

.nav-links a {
  display: block;
  padding: 10px 15px;
  color: #e4c87e;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-links a:hover {
  background-color: rgba(228, 200, 126, 0.1);
}

.nav-links a.active {
  background-color: rgba(228, 200, 126, 0.2);
  font-weight: 500;
}

/* Pages refreshed indicator */
.pages-refreshed-indicator {
  color: #e4c87e;
  display: inline-block;
  margin-left: 8px;
  font-size: 10px;
  animation: pulse 1s infinite;
}

.pages-refreshed {
  animation: highlight-background 2s ease-out;
}

@keyframes pulse {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

@keyframes highlight-background {
  0% { background-color: rgba(228, 200, 126, 0.2); }
  100% { background-color: transparent; }
}
