.chart-config {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.chart-config h3 {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
}

.section {
  margin-bottom: 24px;
}

.section h4 {
  margin-bottom: 16px;
  color: #1f1f1f;
}

.form-row {
  margin-bottom: 16px;
}

.form-row label {
  display: block;
  margin-bottom: 8px;
  color: #595959;
}

.form-row .help-text {
  font-size: 12px;
  color: #8c8c8c;
  margin-top: 4px;
}

.color-pickers {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.color-input {
  width: 60px;
  height: 32px;
  padding: 2px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
}

/* DataSourceConfigModal styles */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.series-item {
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fafafa;
}

.series-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.series-header h5 {
  margin: 0;
  color: #1f1f1f;
}

/* Loading state */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 16px;
  color: #1890ff;
}

/* Error state */
.error-message {
  color: #ff4d4f;
  margin-bottom: 16px;
  padding: 12px;
  background-color: #fff1f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
  font-size: 14px;
}

/* Chart container */
.time-chart-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.time-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.time-chart-title {
  font-size: 16px;
  font-weight: 500;
  color: #1f1f1f;
}

.time-chart-controls {
  display: flex;
  gap: 8px;
}

.time-chart-content {
  flex: 1;
  min-height: 0;
  padding: 16px;
}

.chart-no-data {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8c8c8c;
  font-size: 14px;
}

/* Chart state displays */
.chart-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  color: #666;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1890ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.chart-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  color: #ff4d4f;
  text-align: center;
}

.refresh-button {
  margin-top: 15px;
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #40a9ff;
}

.chart-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  color: #666;
  font-style: italic;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 4px;
}

/* Y-axis settings styles */
.axis-settings {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.axis-column {
  flex: 1;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
}

.axis-column h5 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #444;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
} 