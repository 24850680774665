.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
  background: radial-gradient(ellipse at center, #5a4d36 0%, #332b1e 70%, #211c13 100%);
}

.auth-card {
  width: 100%;
  max-width: 450px;
  padding: 2rem;
  background: #1a1a1a;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  border: 1px solid #333;
}

.auth-title {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #e4c87e;
  font-family: 'Times New Roman', serif;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #e4c87e;
}

.form-input {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  background-color: #252525;
  border: 1px solid #444;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
  color: #fff;
}

/* Override browser autofill styling */
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #252525 inset !important;
  -webkit-text-fill-color: #fff !important;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: #fff;
}

/* Firefox autofill override */
.form-input:autofill {
  background-color: #252525 !important;
  color: #fff !important;
  box-shadow: 0 0 0 30px #252525 inset !important;
}

.form-input:focus {
  border-color: #e4c87e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(228, 200, 126, 0.2);
}

.error-message {
  color: #ff6b6b;
  font-size: 0.85rem;
  margin-top: 0.3rem;
}

.form-button {
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: #e4c87e;
  border: 1px solid #e4c87e;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.form-button:hover {
  background-color: rgba(228, 200, 126, 0.2);
}

.form-button:disabled {
  border-color: #555;
  color: #999;
  cursor: not-allowed;
  background-color: transparent;
}

.auth-link {
  display: block;
  text-align: center;
  margin-top: 1.5rem;
  color: #e4c87e;
  text-decoration: none;
}

.auth-link:hover {
  text-decoration: underline;
  color: #f0d898;
}

.form-row {
  display: flex;
  gap: 1rem;
}

.form-row .form-group {
  flex: 1;
}

.forgot-password {
  text-align: right;
  margin-top: 0.5rem;
}

.forgot-password a {
  color: #e4c87e;
  font-size: 0.85rem;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
  color: #f0d898;
}

.auth-links {
  margin-top: 1.5rem;
  text-align: center;
}

.auth-links a {
  color: #e4c87e;
  text-decoration: none;
}

.auth-links a:hover {
  text-decoration: underline;
  color: #f0d898;
}

.success-message {
  background-color: rgba(72, 187, 120, 0.1);
  border: 1px solid rgba(72, 187, 120, 0.3);
  color: #48bb78;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.text-center {
  text-align: center;
}

.input-error {
  color: #ff6b6b;
  font-size: 0.85rem;
  margin-top: 0.3rem;
}

.auth-button {
  width: 100%;
  padding: 0.75rem;
  background-color: transparent;
  color: #e4c87e;
  border: 1px solid #e4c87e;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 1rem;
}

.auth-button:hover {
  background-color: rgba(228, 200, 126, 0.2);
}

.auth-button:disabled {
  border-color: #555;
  color: #999;
  cursor: not-allowed;
  background-color: transparent;
}

@media (max-width: 480px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
}

/* Activity Log Styles */
.filter-container {
  background-color: #2b2b2b;
  border: 1px solid #444;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  background-color: #333;
  border-bottom: 1px solid #444;
  transition: background-color 0.2s ease;
}

.filter-header:hover {
  background-color: #3a3a3a;
}

.filter-header h4 {
  margin: 0;
  color: #e4c87e;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-badge {
  background-color: #e4c87e;
  color: #333;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 10px;
  font-weight: bold;
}

.filter-toggle-button {
  background: none;
  border: none;
  color: #aaa;
  cursor: pointer;
  font-size: 13px;
  transition: color 0.2s ease;
}

.filter-toggle-button:hover {
  color: #e4c87e;
}

.applied-filters-summary {
  padding: 0.75rem 1rem;
  background-color: #2b2b2b;
  color: #ddd;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
}

.reset-filters-button {
  background: none;
  border: none;
  color: #e45c5c;
  cursor: pointer;
  font-size: 13px;
  transition: opacity 0.2s ease;
  padding: 3px 8px;
  border-radius: 3px;
}

.reset-filters-button:hover {
  background-color: rgba(228, 92, 92, 0.1);
}

.filter-section {
  background-color: #2b2b2b;
  padding: 1rem;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filter-item {
  flex: 1;
  min-width: 200px;
}

.filter-item label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #e4c87e;
}

.filter-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .filter-item {
    min-width: 100%;
  }
  
  .filter-actions {
    flex-direction: row;
    width: 100%;
  }
  
  .filter-actions button {
    flex: 1;
  }
}

.table-container {
  overflow-x: auto;
  margin-bottom: 1.5rem;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2b2b2b;
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden;
}

.data-table th {
  background-color: #333;
  color: #e4c87e;
  text-align: left;
  padding: 0.75rem 1rem;
  font-weight: 500;
}

.data-table td {
  padding: 0.75rem 1rem;
  border-top: 1px solid #444;
  color: #eee;
}

.data-table tr:hover {
  background-color: #333;
}

.data-table .no-data {
  text-align: center;
  padding: 2rem;
  color: #999;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.pagination button {
  background-color: #333;
  color: #e4c87e;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  color: #eee;
}

.link-button {
  background: none;
  border: none;
  color: #e4c87e;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.breadcrumb {
  margin-bottom: 1.5rem;
}

.breadcrumb a {
  color: #e4c87e;
  text-decoration: none;
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.user-email {
  font-size: 0.9rem;
  color: #999;
  margin-left: 0.5rem;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #eee;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.3);
  color: #ff6b6b;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

/* Button styles */
.btn-primary {
  background-color: #e4c87e;
  color: #222;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
}

.btn-secondary {
  background-color: transparent;
  color: #e4c87e;
  border: 1px solid #e4c87e;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: 500;
  cursor: pointer;
} 